<template>
    <b-card>
        <b-row>
            <b-col sm="12">
                <h1>{{ i18nT(`Organizations`) }}</h1>
                <p>{{i18nT(`You can add your Organizations, Companies and Local offices to this account. Each entity will have its own career site, recruitment and employee management space.`)}} </p>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mb-1 mr-1 pl-1"
                    v-b-modal.modal-organization-settings
                >
                    <feather-icon
                        icon="PlusCircleIcon"
                        class="mr-1"
                    />
                    {{ i18nT(`Add new`) }}
                </b-button>
            </b-col>
        </b-row>
        <b-modal
            id="modal-organization-settings"
            :title="i18nT(`Add new organization`)"
            ok-only
            :ok-title="i18nT(`Save`)"
            no-close-on-backdrop
            @ok="validationForm"
        >
            <span>{{ i18nT(`Create your Organizations, Companies and Local offices to this account.`) }}</span>
            <validation-observer ref="simpleRules">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Organization name`)"
                                label-for="orgname"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Organization name"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="itemForModal.orgName"
                                        :placeholder="i18nT(`Organization Name`)"
                                        name="orgname"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>

            </validation-observer>
        </b-modal>

        <div v-if="false">
            <b-row
                v-for="(oneMember, index) in members"
                :key="index"
            >
                <b-col sm="12">
                    {{ oneMember.Label }}
                </b-col>
            </b-row>
        </div>

        <b-table
            hover
            :items="betterMembers"
            :fields="organizationFields"
            :empty-html="emptyHtml"
            show-empty
        >
            <template #cell(Label)="data">
                <b-link :to="{name: 'company-profile-id', params: {orgId: data.item.Id}}">
                    {{ data.item.Label }}
                </b-link>
            </template>
            <template #cell(Email)="data">
                <span>{{ data.item.Email }}</span>
            </template>
            <template #cell(Vat)="data">
                {{ data.item.Vat }}
            </template>
            <template #cell(Id)="data">
                <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    no-caret
                >
                    <template #button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                    </template>
                    <b-dropdown-item @click="onEdit(data)">
                        <feather-icon icon="EditIcon"/>
                        <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="onDelete(data)" :disabled="isCurrentWorskpace(data.item)" v-b-tooltip="isCurrentWorskpace(data.item)? i18nT(`You can't delete your current workspace`) : '' ">
                        <feather-icon
                            icon="TrashIcon"
                            :class="isCurrentWorskpace(data.item) ? 'text-muted' : 'text-danger' "
                        />
                        <span
                            class="align-middle ml-50"
                            :class="isCurrentWorskpace(data.item) ? 'text-muted' : 'text-danger' "
                        >{{ i18nT(`Delete`) }}</span>
                    </b-dropdown-item>
                </b-dropdown>
            </template>
        </b-table>

    </b-card>
</template>

<script>
import {
    VBModal,
    BModal,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BTable,
    BLink,
    BDropdown,
    BDropdownItem,
    VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {useInputImageRenderer} from '@core/comp-functions/forms/form-utils'
import {ref} from '@vue/composition-api'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAuth from '@/auth/useAuth'
import {BASE_URL} from "@core/utils/constants";

export default {
    name: 'Organizations',
    directives: {
        Ripple,
        'b-modal': VBModal,
        'b-tooltip': VBTooltip
    },
    components: {
        BModal,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        BTable,
        ValidationProvider,
        ValidationObserver,
        BLink,
        BDropdown,
        BDropdownItem,
    },
    props: {
        generalData: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            optionsLocal: [],
            profileFile: null,
            members: [],
            organizationFields: [
                {
                    key: 'Label',
                    label: 'Names',
                    sortable: true
                },
                {
                    key: 'BusinessEmail',
                    label: 'Email',
                    sortable: true
                },
                {
                    key: 'Vat',
                    label: 'VAT #'
                },
                {
                    key: 'Id',
                    label: 'Actions',
                    class: 'tbl-actions'
                }
            ],
            itemForModal: {
                orgName: ''
            },
        }
    },
    methods: {
        isCurrentWorskpace(item){
            return item.Id === useAuth.getCurWorkspace().Id
        },
        resetForm() {
            this.itemForModal = {
                orgName: ''
            }
        },
        editOrg(orgId) {
            this.$router.push({path: '/re/company-profile/' + orgId})
        },
        validationForm(event) {
            event.preventDefault()

            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const formData = new FormData()

                    formData.append('Label', this.itemForModal.orgName)

                    this.$http
                        .post('companies', formData)
                        .then(({data}) => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(data),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })

                            this.$bvModal.hide('modal-organization-settings')

                            this.$http
                                .post('main/switchCompany', {
                                    company_id: data.data.Id
                                })
                                .then(() => {
                                    useAuth.setCurWorkspace(data.data)
                                    window.location.replace('/re/company-profile/')
                                })
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onEdit(row) {
          console.log('company',row);
            this.$http
                .post('main/switchCompany', {
                    company_id: row.item.Id
                })
                .then(() => {
                    useAuth.setCurWorkspace(row.item)
                    window.location.replace('/re/company-profile/')
                })
        },
        loadData() {
            this.$http.get(`companies`).then(({data}) => {
                this.members = data.data
            })
        },
        onDelete(row) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(({value}) => {
                if (value) {
                    this.$http.delete(`companies?id=${row.item.Id}`).then(res => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.successMessage(res),
                                icon: 'InfoIcon',
                                variant: 'success'
                            }
                        })
                        this.loadData()
                    }).catch(err => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(err),
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        })
                    })
                }
            })
        }
    },
    setup() {
        const refInputEl = ref(null)
        const previewEl = ref(null)

        const {inputImageRenderer} = useInputImageRenderer(refInputEl, previewEl)

        return {
            refInputEl,
            previewEl,
            required,
            inputImageRenderer
        }
    },
    computed: {
        emptyHtml() {
            return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>" + this.i18nT(`No matching records found`) + "</h4>";
        },
        betterMembers() {
            let betterMembers = []
            if (this.members.length > 0) {
                this.members.forEach(function (oneMember) {
                    if (oneMember.PublicationStatus === 'ACTIVE') {
                        betterMembers.push(oneMember)
                    }
                })
            }
            return betterMembers
        }
    },
    mounted() {
        this.loadData()

    }
}
</script>
